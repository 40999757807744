import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import i18 from "i18next";


const useStyles = makeStyles((theme) => ({
  features: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(10),
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderColor: theme.palette.background.emphasis,
    }
  },
  iconWrapper: {
    backgroundColor: fade(theme.palette.background.emphasis, .6),
  },
}
));

export default function Component(props) {
  const classes = useStyles();
  const { handleTabChange } = props;


  return (
    <section>
      <Container maxWidth="lg">
        <Box pt={10} pb={12}>
          <Grid container spacing={10}>
            <Grid item xs={12} md={12}>
              <Typography variant="overline" color="textSecondary"></Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography color="primary" variant="h3" component="span">{i18.t("HowItWorksCustom4.h3.primary")}</Typography>
                <Typography variant="h3" component="span">{i18.t("HowItWorksCustom4.h3.span")}</Typography>
              </Typography>
              <Box mt={3} display="flex" alignItems="center">
                <Button onClick={handleTabChange} variant="contained" color="primary" className={classes.primaryAction} style={{ marginRight: '20px' }}>Documents</Button>
                <Link href="https://github.co.jp/">Github</Link>
              </Box>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </section>

    // justify-content, flex-end;
    // align-items, flex-end

  );
}
import React from 'react';

import HowItWorks2 from '../components/how-it-works/HowItWorks2';
import HowItWorks5 from '../components/how-it-works/HowItWorks5';
import Blog1 from '../components/blog/Blog1';
import HowItWorksCustom4 from './HowItWorksCustom4';
import Footer3 from './footers/Footer3'; // Footer3.js
import i18 from "i18next";

const meta = {
}

export default function Documents() {
  return (
    <React.Fragment>
      <div className="container p-4">

        <div className="row">
          <div className="col-12">

            <HowItWorks5 content={{
              "badge": "\n",
              "header-p1": "MillionaireToken",
              "header-p2": i18.t("HowItWorks5.Mil.header-p2"),
              "description": "\n",
              "image": "/MillionaireToken_imagechart.jpeg"

            }} />

            <HowItWorks2 content={{
              "badge": "\n",
              "header-p1": "MillionaireToken",
              "header-p2": i18.t("HowItWorks2.Mil.header-p2"),
              "description": "\n",
              "col1-header": i18.t("HowItWorks2.Mil.col1-header"),
              "col2-header": i18.t("HowItWorks2.Mil.col2-header"),
              "col3-header": i18.t("HowItWorks2.Mil.col3-header"),
              "col4-header": i18.t("HowItWorks2.Mil.col4-header"),
              "image": "/image_millionaireToken.svg"
            }} />

            <Blog1 content={{
              "header-p1": "MillionaireToken",
              "header-p2": i18.t("Blog1.Mil.header-p2"),
              "paragraph1": i18.t("Blog1.Mil.paragraph1"),
              "paragraph2": i18.t("Blog1.Mil.paragraph2"),
              "list1": i18.t("Blog1.Mil.list1"),
              "list2": i18.t("Blog1.Mil.list2"),
              "list3": i18.t("Blog1.Mil.list3"),

              "paragraph3": i18.t("Blog1.Mil.paragraph3"),
              "list4": i18.t("Blog1.Mil.list4"),
              "list5": i18.t("Blog1.Mil.list5"),
              "list6": i18.t("Blog1.Mil.list6"),

              "paragraph4": i18.t("Blog1.Mil.paragraph4"),
              "list7": i18.t("Blog1.Mil.list7"),
              "list8": i18.t("Blog1.Mil.list8"),
              "list9": "",

              "paragraph5": i18.t("Blog1.Mil.paragraph5"),
              "list10": i18.t("Blog1.Mil.list10"),
              "list11": i18.t("Blog1.Mil.list11"),
              "list12": "",

              "paragraph6": `予備`

            }} />

            <HowItWorks2 content={{
              "badge": "\n",
              "header-p1": "MillionaireChildrenToken",
              "header-p2": i18.t("HowItWorks2.Child.header-p2"),
              "description": "\n",
              "col1-header": i18.t("HowItWorks2.Child.col1-header"),
              "col2-header": i18.t("HowItWorks2.Child.col2-header"),
              "col3-header": i18.t("HowItWorks2.Child.col3-header"),
              "col4-header": "",
              "image": "/image_childrenToken.svg"

            }} />

            <Blog1 content={{
              "header-p1": "MillionaireChildrenToken",
              "header-p2": i18.t("Blog1.Child.header-p2"),
              "paragraph1": i18.t("Blog1.Child.paragraph1"),
              "paragraph2": i18.t("Blog1.Child.paragraph2"),
              "list1": i18.t("Blog1.Child.list1"),
              "list2": i18.t("Blog1.Child.list2"),
              "list3": i18.t("Blog1.Child.list3"),

              "paragraph3": i18.t("Blog1.Child.paragraph3"),
              "list4": i18.t("Blog1.Child.list4"),
              "list5": i18.t("Blog1.Child.list5"),
              "list6": i18.t("Blog1.Child.list6"),

              "paragraph4": i18.t("Blog1.Child.paragraph4"),
              "list7": i18.t("Blog1.Child.list7"),
              "list8": "",
              "list9": "",

              "paragraph5": i18.t("Blog1.Child.paragraph5"),
              "list10": i18.t("Blog1.Child.list10"),
              "list11": i18.t("Blog1.Child.list11"),
              "list12": "",

              "paragraph6": `予備`,

            }} />



            <HowItWorks5 content={{
              "badge": "\n",
              "header-p1": "MillionaireToken",
              "header-p2": i18.t("HowItWorks5.Flow.header-p2"),
              "description": "\n",

              "image": "/flowchart.svg"

            }} />

            <HowItWorksCustom4
              handleTabChange={() =>
                this._handleTabChange(null, 1)
              }
            />

            <Footer3 content={null} />


          </div>
        </div>

      </div>

    </React.Fragment>
  );
}

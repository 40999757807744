import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';


const useStyles = makeStyles((theme) => ({
  section: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  box: {
    paddingTop: theme.spacing(8),
    paddingBottom:  theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom:  theme.spacing(2),
    }
  },
  header: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 0
    }
  },
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    }
  },
  img: {
    height: 768
  },
}
));

export default function Component(props) {
  const classes = useStyles();

  return (
<section className={classes.section} style={{ backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")' }}>
  <Container maxWidth="lg">
    <Box className={classes.box}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box display="flex" height="100%">
            <Box my="auto" width="100%">
              <Container maxWidth="sm" className={classes.header}>
                <Typography variant="h2" component="h2" gutterBottom={true}>
                  <Typography variant="h2" component="span" color="primary">Millionaire Token </Typography>

                </Typography>
              </Container>
              <Container maxWidth="sm">

                <Typography variant="h2" component="span">このトークンはあなたが億万長者であることを証明します。</Typography>
              </Container>
              <Container maxWidth="sm">
                <Typography variant="subtitle1" color="textSecondary">現在○○人の億万長者がこのトークンを保有しています。</Typography>
                <Box mt={3}>
                  <Button variant="contained" color="secondary" className={classes.primaryAction}>Mint</Button>
                  <Link href="#">Link Default</Link>
                </Box>
              </Container>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia image="https://images.unsplash.com/photo-1593642532400-2682810df593?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=60&blend=ddd&sat=-30&blend-mode=multiply" className={classes.img}></CardMedia>
          </Card>
        </Grid>
      </Grid>
    </Box>
  </Container>
</section>
  );
}
import React from "react";
import ReactDOM from "react-dom";
import { Dapp } from "./components/Dapp";
import HorizontalNavsCustom1 from './components-custom/HorizontalNavsCustom1';
import HeadersCustom2 from './components-custom/HeadersCustom2';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme';
import "./i18n/configs"; //i18

// We import bootstrap here, but you can remove if you want
import "bootstrap/dist/css/bootstrap.css";

// This is the entry point of your application, but it just renders the Dapp
// react component. All of the logic is contained in it.

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.StrictMode>
      <Dapp />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);
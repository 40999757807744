import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import i18 from "i18next";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '100%',
    height: '256px',
    marginBottom: theme.spacing(2)
  },
}
));

export default function Component(props) {
  const classes = useStyles();

  return (
    <section>
      <Container maxWidth="lg">
        <Box py={8}>
          <Box mb={9} textAlign="center">
            <Container maxWidth="sm">
              <Typography variant="overline" color="textSecondary" paragraph={true}>TEAM</Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography variant="h3" component="span" color="primary"> </Typography>
                <Typography variant="h3" component="span">{i18.t("TeamCustom5.h3")}</Typography>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true}></Typography>
            </Container>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={3}>
              {/* ツイッターリンクを貼る */}
              <Link href="#" color="inherit" underline="none">
                <Avatar alt="" variant="rounded" src="/20151119181415-2.jpg" className={classes.avatar} />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6" component="h6" gutterBottom={true}>Takami</Typography>
                  <ArrowRightAltIcon />
                </Box>
                <Typography variant="body1" color="textSecondary" component="span">{i18.t("TeamCustom5.body1")}</Typography>
              </Link>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </section>
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Link from '@material-ui/icons/Link';
import ChildCare from '@material-ui/icons/ChildCare';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

// Japanese and English language
import i18 from "i18next";

const useStyles = makeStyles((theme) => ({
}
));

export default function Component(props) {
  const classes = useStyles();

  return (
    <section>
      <Container maxWidth="lg">
        <Box py={6}>
          <Box textAlign="center" mb={8}>
            <Container maxWidth="sm">
              <Typography variant="h3" component="h2" gutterBottom={true}>{i18.t("FeaturesCustom3.h3")}</Typography>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true}>{i18.t("FeaturesCustom3.subtitle1_1")}</Typography>
            </Container>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <StopScreenShare color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                  {i18.t("FeaturesCustom3.subtitle1_2")} </Typography>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                  {i18.t("FeaturesCustom3.subtitle1_3")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <Link color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                  {i18.t("FeaturesCustom3.subtitle1_4")}</Typography>
                <Typography variant="subtitle1" component="p" color="textSecondary">

                  {i18.t("FeaturesCustom3.subtitle1_5")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <ChildCare color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                  {i18.t("FeaturesCustom3.subtitle1_6")}</Typography>
                <Typography variant="subtitle1" component="p" color="textSecondary">

                  {i18.t("FeaturesCustom3.subtitle1_7")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Box mb={2}>
                  <BusinessCenterIcon color="primary" fontSize="large" />
                </Box>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                  {i18.t("FeaturesCustom3.subtitle1_8")}</Typography>
                <Typography variant="subtitle1" component="p" color="textSecondary">

                  {i18.t("FeaturesCustom3.subtitle1_9")}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>

  );
}
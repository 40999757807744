import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import i18 from "i18next";


const useStyles = makeStyles((theme) => ({
  section: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  box: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    }
  },
  header: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 0
    }
  },
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    }
  },
  img: {
    height: 768
  },
}
));


export default function HeaderCustom2(props) {
  const classes = useStyles();
  const {
    mintMillionaireToken,
    totalSupply,
    balanceOfMillionaireToken,
    totalAssetValue,
    balanceOfMillionaireChildrenToken,
    createMillionaireChildrenToken,
    mintMillionaireChildrenToken,
    millionaireChildrenTokenData,
  } = props;

  return (
    <section className={classes.section} style={{ backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")' }}>
      <Container maxWidth="lg">
        <Box className={classes.box}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%">
                <Box my="auto" width="100%">
                  <Container maxWidth="sm" className={classes.header}>
                    <Typography variant="h2" component="h2" gutterBottom={true}>
                      <Typography variant="h2" component="span" color="primary">
                        Millionaire Token
                      </Typography>
                    </Typography>
                  </Container>
                  <Container maxWidth="sm">
                    <Typography variant="h2" component="span">
                      {i18.t("HeadersCustom2.h2")}
                    </Typography>
                  </Container>
                  <Container maxWidth="sm">
                    <Typography variant="subtitle1" color="textSecondary">
                      {i18.t("HeadersCustom2.subtitle1_1.1")}{totalSupply}{i18.t("HeadersCustom2.subtitle1_1.2")}
                    </Typography>
                    <Box mt={3}>
                      <p>
                        {i18.t("HeadersCustom2.p.1")}<b>${Math.round(totalAssetValue)}</b>
                      </p>

                      {balanceOfMillionaireToken.gt(0) && (
                        <p>
                          <b>{i18.t("HeadersCustom2.p.2")}</b>
                        </p>
                      )}

                      <Button
                        onClick={mintMillionaireToken}
                        disabled={balanceOfMillionaireToken.gt(0) || totalAssetValue <= 1000000}
                        variant="contained"
                        color="secondary"
                        className={classes.primaryAction}
                      >
                        Mint Millionaire Token
                      </Button>
                    </Box>
                    <Box mt={3}>
                      {/* MillionaireTokenを持っていてかつChildrenToken持っていない場合はクリエイトできることを表示 */}
                      {balanceOfMillionaireToken.gt(0) && balanceOfMillionaireChildrenToken === undefined && (
                        <p>
                          <b>{i18.t("HeadersCustom2.p.3")}</b>
                        </p>
                      )}

                      {balanceOfMillionaireToken.gt(0) && (
                        <Button
                          onClick={createMillionaireChildrenToken}
                          disabled={balanceOfMillionaireChildrenToken !== undefined}
                          variant="contained"
                          color="secondary"
                          className={classes.primaryAction}
                        >
                          Create ChildrenToken
                        </Button>
                      )}
                    </Box>
                    <Box mt={3}>
                      {/* MillionaireTokenを持っている場合はすでに持っていることを表示。 */}
                      {/* {balanceOfMillionaireChildrenToken !== undefined && (
                        <p>
                          <b>You already create Millionaire Children Token.</b>
                        </p>
                      )} */}
                      {/* （アセットを持っているヒトにのみ）子を
                <Box mt={3}>
                  {/* Createした子トークンがある場合に表示 */}
                      {balanceOfMillionaireChildrenToken != undefined &&
                        (
                          <p>
                            {i18.t("HeadersCustom2.p.4")}
                            <b>{millionaireChildrenTokenData.name}</b>
                            {i18.t("HeadersCustom2.p.5")}
                          </p>
                        )}

                      {balanceOfMillionaireChildrenToken != undefined &&
                        (
                          <p><b>{i18.t("HeadersCustom2.p.6")}</b></p>

                        )}

                      {balanceOfMillionaireChildrenToken != undefined && (
                        <Button type="button" className="btn btn-primary"
                          onClick={mintMillionaireChildrenToken.bind(this)}
                          variant="contained" color="secondary"
                        >
                          Mint ChildrenToken
                        </Button>
                      )}

                      {/* 持っている子Tokenを表示 */}

                      {balanceOfMillionaireChildrenToken != undefined && (
                        <p>
                          {i18.t("HeadersCustom2.p.7")}
                          <b>
                            {balanceOfMillionaireChildrenToken.toString()}
                          </b>
                          {i18.t("HeadersCustom2.p.8")}
                          <b>
                            {millionaireChildrenTokenData.symbol}
                          </b>
                          {i18.t("HeadersCustom2.p.9")}
                        </p>
                      )}
                      {/* これまでミントされた総数を表示 */}
                      {balanceOfMillionaireChildrenToken != undefined && (
                        <p>
                          {i18.t("HeadersCustom2.p.10")}
                          <b>
                            {millionaireChildrenTokenData.totalSupply.toString()}
                          </b>
                          {i18.t("HeadersCustom2.p.8")}
                          <b>
                            {millionaireChildrenTokenData.symbol}
                          </b>
                          {i18.t("HeadersCustom2.p.11")}
                        </p>
                      )}
                    </Box>

                  </Container>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardMedia image="/image_millionaireToken.svg" className={classes.img}></CardMedia>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}